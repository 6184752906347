.navbar{
	min-height: 5px;
}
.navbar-collapse{
	padding: 0;
}
.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: rgba(#fff,.22);
}
.top-menu {

	border-radius: 0;
	border:none;
	background: #3fa2d5;
    font-family: 'Exo 2', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 1px;
    position: relative;

	.client-number{
		padding-top:20px; padding-right:20px; color:#fff; font-size:10px;
		float: right;
	}

	.navbar-toggle{
		color:#fff;
		font-size: 16px;
	}
	.navbar-toggle2{
		float:left;
	}
	ul {
	    /* position: relative; */
	    background: #3fa2d5;
	    font-size: 0;

		li{
			a{
				font-size: 19px;
			    color: #fff;
			    text-shadow: 1px 1px 0px rgba(0,0,0, .11);
			    padding: 0 30px;
			    line-height: 60px;
			    height: 60px;
			    display: block;
			    position: relative;
				transition: all .4s;

				&:after{
				    content: '';
				    position: absolute;
				    right: 0px;
				    top: 13px;
				    height: 34px;
				    border-right: 1px solid rgba(#fff,.11);
				    display: block;
				}
			}
		}
	}

	@media (max-width: 1200px) {
		ul{
			li{
				a{
					padding: 0 20px;
					font-size: 17px;
				}
			}
		}
	}


	@media (max-width: 992px) {
		text-align: center;
		.client-number{
			float: none;
			text-align: center;
		}
		ul{
			float:none;
			margin: 0 auto;
			text-align: center;
			li{
				display: inline-block;
				float: none;

				a{
					padding: 0 15px;
					font-size: 16px;
				}
				&:last-child a:after{
					border-right: none;
				}
			}
		}
	}
	@media (max-width: 768px) {
		ul{
			li{
				display: block;
				a{

				}
			}
		}
	}
}

.secondary-menu{
	@extend .top-menu;
	background: #6cc4e4;
	min-height: 5px;

	ul{
		background: #6cc4e4;
		li{
			a{
				line-height: 40px;
				height: 40px;
				font-size: 14px;
				&:after{
				    content: '';
				    position: absolute;
				    right: 0px;
				    top: 13px;
				    height: 14px;
				    border-right: 1px solid rgba(#fff,.11);
				    display: block;
				}
			}
		}
	}
}
