footer {
    padding: 40px 0px;

    border-top:1px solid #ddd;
}
.bottom-menu {
    font-family: 'Exo 2', sans-serif;
    color: #ccc;
    font-size: 16px;
    margin-bottom: 30px;
}

.small-text {
    font-size: 10px;
    color: #999;
    text-align: right;
    width: 100%;
    margin-top: 30px;
}