header {
    padding-top: 40px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 30px;

    a{
    	&:hover, &:active, &:focus{
    		text-decoration: none;
    	}
    }

    h4{
    	margin: 0;
		font-size: 14.7px;
		text-transform: uppercase;
		margin-bottom: 7px;
		letter-spacing: -.3px;
		font-weight: normal;
    }

    .socials{
		margin-bottom: 30px;
    }

	#more-contacts{
		.contacts{
			padding: 30px 0 20px 0;

		}
	}
}
