header {
  padding-top: 40px;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 30px;
}

header a:hover, header a:active, header a:focus {
  text-decoration: none;
}

header h4 {
  margin: 0;
  font-size: 14.7px;
  text-transform: uppercase;
  margin-bottom: 7px;
  letter-spacing: -.3px;
  font-weight: normal;
}

header .socials {
  margin-bottom: 30px;
}

header #more-contacts .contacts {
  padding: 30px 0 20px 0;
}

.navbar {
  min-height: 5px;
}

.navbar-collapse {
  padding: 0;
}

.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.22);
}

.top-menu, .secondary-menu {
  border-radius: 0;
  border: none;
  background: #3fa2d5;
  font-family: 'Exo 2', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 1px;
  position: relative;
}

.top-menu .client-number, .secondary-menu .client-number {
  padding-top: 20px;
  padding-right: 20px;
  color: #fff;
  font-size: 10px;
  float: right;
}

.top-menu .navbar-toggle, .secondary-menu .navbar-toggle {
  color: #fff;
  font-size: 16px;
}

.top-menu .navbar-toggle2, .secondary-menu .navbar-toggle2 {
  float: left;
}

.top-menu ul, .secondary-menu ul {
  /* position: relative; */
  background: #3fa2d5;
  font-size: 0;
}

.top-menu ul li a, .secondary-menu ul li a {
  font-size: 19px;
  color: #fff;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.11);
  padding: 0 30px;
  line-height: 60px;
  height: 60px;
  display: block;
  position: relative;
  transition: all .4s;
}

.top-menu ul li a:after, .secondary-menu ul li a:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 13px;
  height: 34px;
  border-right: 1px solid rgba(255, 255, 255, 0.11);
  display: block;
}

@media (max-width: 1200px) {
  .top-menu ul li a, .secondary-menu ul li a {
    padding: 0 20px;
    font-size: 17px;
  }
}

@media (max-width: 992px) {
  .top-menu, .secondary-menu {
    text-align: center;
  }
  .top-menu .client-number, .secondary-menu .client-number {
    float: none;
    text-align: center;
  }
  .top-menu ul, .secondary-menu ul {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .top-menu ul li, .secondary-menu ul li {
    display: inline-block;
    float: none;
  }
  .top-menu ul li a, .secondary-menu ul li a {
    padding: 0 15px;
    font-size: 16px;
  }
  .top-menu ul li:last-child a:after, .secondary-menu ul li:last-child a:after {
    border-right: none;
  }
}

@media (max-width: 768px) {
  .top-menu ul li, .secondary-menu ul li {
    display: block;
  }
}

.secondary-menu {
  background: #6cc4e4;
  min-height: 5px;
}

.secondary-menu ul {
  background: #6cc4e4;
}

.secondary-menu ul li a {
  line-height: 40px;
  height: 40px;
  font-size: 14px;
}

.secondary-menu ul li a:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 13px;
  height: 14px;
  border-right: 1px solid rgba(255, 255, 255, 0.11);
  display: block;
}

footer {
  padding: 40px 0px;
  border-top: 1px solid #ddd;
}

.bottom-menu {
  font-family: 'Exo 2', sans-serif;
  color: #ccc;
  font-size: 16px;
  margin-bottom: 30px;
}

.small-text {
  font-size: 10px;
  color: #999;
  text-align: right;
  width: 100%;
  margin-top: 30px;
}

.img-responsive {
  display: inline-block;
}

#Promo {
  background: transparent url("../images/promo.jpg") center center no-repeat;
  background-size: cover;
  height: 360px;
  position: relative;
}

@media (max-width: 1200px) {
  #Promo {
    height: 320px;
  }
}

@media (max-width: 992px) {
  #Promo {
    background-position: left center;
    margin-bottom: 100px;
    height: 200px;
  }
}

#Promo .caption {
  position: absolute;
  top: 80px;
  left: 60%;
  right: 30px;
}

#Promo .caption h1 {
  color: #fff;
  font-weight: 200;
  text-shadow: 1px 1px 1px rgba(20, 37, 57, 0.44);
}

#Promo .caption h1 small {
  display: block;
  font-weight: bold;
  font-size: 22px;
  color: #fff;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  #Promo .caption {
    top: 90px;
    left: 56%;
  }
  #Promo .caption h1 {
    font-size: 36px;
  }
  #Promo .caption h1 small {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  #Promo .caption {
    top: auto;
    bottom: -150px;
    left: 0px;
    right: 0px;
    height: 150px;
    background: #244b6f;
  }
  #Promo .caption h1 {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
  }
  #Promo .caption h1 small {
    font-size: 14px;
  }
}

h1, h2, h3, h4 {
  font-family: 'Exo 2', sans-serif;
}

h1 {
  font-weight: 200;
  font-size: 42px;
}

h2 {
  color: #21486d;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
}

h3 {
  color: #244c6f;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  max-width: 450px;
}

h4 {
  margin-top: 30px;
}

.text {
  padding: 60px 0;
}

.text.bg-gray {
  background: #e8e8e9;
  padding: 60px 30px;
}

.text.bg-gray .lead {
  color: #555;
}

.text h2 {
  margin-top: 0;
  margin-bottom: 40px;
}

.text .lead {
  color: #64b0db;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Exo 2', sans-serif;
}

.order {
  position: relative;
  background: transparent url("../images/we-know.jpg") center center no-repeat;
  background-size: cover;
  /*height: 260px;*/
  padding: 60px 30px;
}

.order h3 {
  text-align: center;
  margin-top: 0;
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 22px;
  text-shadow: 1px 1px 1px rgba(20, 37, 57, 0.44);
  max-width: 600px;
  margin: 0 auto;
}

.order h3 strong {
  font-weight: 700;
  font-size: 36px;
  display: block;
  margin-top: 20px;
}

.order:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 30px 30px 0;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: 0px;
  left: 50%;
  margin-left: -30px;
}

.order .iphone {
  position: absolute;
  top: 20px;
  right: -40px;
}

@media (max-width: 1200px) {
  .order h3 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .order {
    background-position: left center;
  }
  .order h3 {
    font-size: 16px;
  }
  .order h3 strong {
    font-size: 18px;
  }
}

.number-icon {
  background: #fff;
  height: 42px;
  width: 42px;
  color: #6cc4e4;
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  font-size: 24px;
  font-family: 'Exo 2', sans-serif;
  font-weight: 200;
}

.recomend-item {
  margin-bottom: 40px;
}
