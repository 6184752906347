@import "_header.scss";
@import "_navigation.scss";
@import "_footer.scss";

.img-responsive{
	display: inline-block;
}

#Promo{
	background:transparent url('../images/promo.jpg') center center no-repeat;
	background-size: cover;
	height: 360px;
	position: relative;

	@media (max-width: 1200px) {
		height:320px;
	}
	@media (max-width: 992px) {
		background-position: left center;
		margin-bottom: 100px;
		height: 200px;
	}

	.caption{
		position: absolute;
		top: 80px; left:60%;
		right:30px;

		h1{
			color:#fff;
			font-weight: 200;
			text-shadow: 1px 1px 1px rgba(#142539, .44);

			small{
				display: block;
				font-weight: bold;
				font-size: 22px;
				color:#fff;
				margin-top: 20px;
			}
		}
		@media (max-width: 1200px) {
			top:90px;
			left:56%;

			h1{
				font-size: 36px;

				small{
					font-size: 18px;
				}

			}
		}
		@media (max-width: 992px) {
			top:auto;
			bottom: -150px; left:0px; right:0px;
			height:150px;
			background:#244b6f;

			h1{
				margin-top: 30px;
				text-align: center;
				font-size: 24px;
				small{
					font-size: 14px;
				}
			}
		}


	}
}

h1, h2, h3, h4{
	font-family: 'Exo 2', sans-serif;
}

h1 {
	font-weight: 200;
	font-size: 42px;
}

h2{
	color: #21486d;
	font-size: 30px;
	text-transform: uppercase;
	text-align: center;
	font-weight: normal;
}

h3{
	color:#244c6f;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 20px;
	max-width:450px;
}

h4{
	margin-top: 30px;
}

.text{
	padding: 60px 0;

	&.bg-gray{
		background: #e8e8e9;
		padding: 60px 30px;

		.lead{
			color:#555;
		}
	}
	h2{
		margin-top: 0;
		margin-bottom: 40px;
	}

	.lead{
		color:#64b0db;
		font-size: 18px;
		font-weight: 300;
		font-family: 'Exo 2', sans-serif;
	}
}

.order{
	position: relative;
	background:transparent url('../images/we-know.jpg') center center no-repeat;
	background-size: cover;
	/*height: 260px;*/
	padding: 60px 30px;

	h3{
		text-align: center;
		margin-top: 0;
		color:#fff;
		text-align: center;
		font-weight: 300;
		font-size: 22px;
		text-shadow: 1px 1px 1px rgba(#142539, .44);
		max-width: 600px;
		margin: 0 auto;

		strong{
			font-weight: 700;
			font-size: 36px;
			display: block;
			margin-top: 20px;
		}
	}
	&:after {
		content: '';
		position: absolute;
		border-style: solid;
		border-width: 30px 30px 0;
		border-color: #FFFFFF transparent;
		display: block;
		width: 0;
		z-index: 1;
		top: 0px;
		left: 50%; margin-left:-30px;
	}
	.iphone{
		position: absolute;
		top:20px;
		right:-40px;
	}
	@media (max-width: 1200px) {
		h3{
			font-size: 20px;
		}
	}

	@media (max-width: 992px) {
		background-position: left center;

		h3{
			font-size: 16px;
			strong{
				font-size: 18px;
			}
		}
	}

}

.number-icon{
	background:#fff;
	height: 42px; width: 42px;
	color:#6cc4e4;
	line-height: 42px;
	text-align: center;
	border-radius: 50%;
	font-size: 24px;
	font-family: 'Exo 2', sans-serif;
	font-weight: 200;
}

.recomend-item{
	margin-bottom: 40px;
}
